import React from "react";

export const TableHeaderAlignLeft = ({
  columns,
}: Readonly<{
  columns: ReadonlyArray<string>;
}>): JSX.Element => (
  <thead className="bg-white">
    <tr>
      {columns.map((column, index, array) =>
        index < array.length - 1 ? (
          <th
            className="text-xxs px-2 py-4 text-left"
            key={`${column}-${index}`}
            scope="col"
          >
            {column}
          </th>
        ) : (
          <th className="relative py-3" key={column} scope="col">
            <span className="sr-only">{column}</span>
          </th>
        ),
      )}
    </tr>
  </thead>
);

export const TableHeaderAlignRight = ({
  columns,
}: Readonly<{
  columns: ReadonlyArray<string>;
}>): JSX.Element => (
  <thead className="bg-white">
    <tr>
      {columns.map((column, index, array) =>
        index < array.length - 1 ? (
          <th
            className="text-xxs px-2 py-4 text-left"
            key={`${column}-${index}`}
            scope="col"
          >
            {column}
          </th>
        ) : (
          <th
            className="text-xxs px-2 py-4 text-right"
            key={column}
            scope="col"
          >
            {column}
          </th>
        ),
      )}
    </tr>
  </thead>
);

export const TableHeaderAlignRightTwo = ({
  columns,
}: Readonly<{
  columns: ReadonlyArray<string>;
}>): JSX.Element => (
  <thead className="bg-white">
    <tr>
      {columns.map((column, index, array) =>
        index < array.length - 2 ? (
          <th
            className="text-xxs px-2 py-4 text-left"
            key={`${column}-${index}`}
            scope="col"
          >
            {column}
          </th>
        ) : (
          <th
            className="text-xxs px-2 py-4 text-right"
            key={column}
            scope="col"
          >
            {column}
          </th>
        ),
      )}
    </tr>
  </thead>
);
