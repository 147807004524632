import React from "react";
import { useConfirm } from "./useConfirm";
import { ArrowButton } from "@bay1/ui";
import classNames from "classnames";

export const ConfirmModal = () => {
  const { prompt = "", isOpen = false, proceed, cancel } = useConfirm();

  return (
    <>
      {isOpen && prompt !== "" && cancel !== null && proceed !== null ? (
        <>
          <div
            className="bg-ash fixed inset-0 z-40 overflow-y-auto opacity-60"
            onClick={cancel}
          />
          <div
            className="pointer-events-none fixed inset-0 z-50 overflow-y-auto"
            data-testid="modal::confirmModal"
          >
            <div className="flex min-h-screen items-center justify-center px-4 pb-20 pt-4 text-center sm:p-0">
              <div
                aria-labelledby="modal-confirm"
                aria-modal="true"
                className="rounded-highnote pointer-events-auto relative inline-block overflow-hidden bg-white text-left align-bottom antialiased shadow-lg sm:w-full sm:max-w-md sm:align-middle"
                role="dialog"
              >
                <div className="font-display p-6 pb-4 text-lg font-medium">
                  {prompt.title}
                </div>
                <p className="px-6">{prompt.description}</p>
                <hr className="border-ash mt-7" />
                <div className="flex items-center justify-between p-6">
                  <button
                    className="bg-bone hover:bg-ash transition-200 rounded-full px-5 py-2 transition-colors"
                    onClick={cancel}
                    data-testid="modal::confirmModal::cancelButton"
                  >
                    Cancel
                  </button>
                  <button
                    className={classNames(
                      "transition-200 flex items-center rounded-full px-5 py-2 text-white transition-colors",
                      {
                        "bg-red hover:bg-rose-600": prompt.isDestructive,
                        "bg-black hover:bg-green-400 hover:text-black":
                          !prompt.isDestructive,
                      },
                    )}
                    onClick={proceed}
                    data-testid="modal::confirmModal::confirmButton"
                  >
                    {prompt.buttonText}
                    <ArrowButton isPrimary />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
