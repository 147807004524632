/* eslint-disable import/no-duplicates */
import { useFormikContext } from "formik";
import { PropsWithChildren } from "react";
import getDeep from "lodash.get";
import classNames from "classnames";
import { ErrorMessage } from "./ErrorMessage";
import { FieldLabel } from "./FieldLabel";

type SelectFieldProps = {
  name: string;
  required?: boolean;
  labelText: string;
  label?: JSX.IntrinsicElements["label"];
  transformValue?: (value: string) => string;
  disabled?: boolean;
  select?: JSX.IntrinsicElements["select"];
  placeholder?: string;
  relatedError?: boolean;
};

export const SelectField = ({
  children,
  label,
  labelText,
  name,
  placeholder,
  required,
  disabled = false,
  relatedError,
}: PropsWithChildren<Readonly<SelectFieldProps>>): JSX.Element => {
  const { errors, handleBlur, handleChange, isSubmitting, touched, values } =
    useFormikContext();

  const hasError =
    (getDeep(touched, name) && getDeep(errors, name)) || relatedError;

  return (
    <>
      <FieldLabel
        hasError={hasError}
        isSubmitting={isSubmitting}
        label={label}
        labelText={labelText}
        name={name}
        required={required}
      />
      <select
        className={classNames({
          baseInputWithError: hasError,
          baseInput: !hasError,
          "cursor-not-allowed": disabled,
        })}
        data-testid={`form::select::${name}`}
        disabled={disabled}
        id={name}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        value={getDeep(values, name) ?? ""}
      >
        {placeholder !== undefined && (
          <option disabled hidden value="">
            {placeholder}
          </option>
        )}

        {children}
      </select>

      <ErrorMessage keyPath={name} />
    </>
  );
};
