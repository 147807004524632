import React from "react";
import { LoadMoreButton } from "@bay1/ui";

export const LoadMore = ({
  hasNextPage,
  isRefreshing,
  isLoadingMore,
  onClick,
}: Readonly<{
  hasNextPage: boolean;
  isRefreshing?: boolean;
  isLoadingMore: boolean;
  onClick: () => void;
}>): JSX.Element => (
  <div className="relative mb-5">
    <div aria-hidden="true" className="absolute inset-0 flex items-center">
      <div className="border-gray w-full border-t" />
    </div>
    <div className="relative flex justify-center">
      <LoadMoreButton
        hasNextPage={hasNextPage}
        isLoading={(isRefreshing ?? false) || isLoadingMore}
        onClick={onClick}
      />
    </div>
  </div>
);
